import React from "react";
import { Link } from "react-router-dom";

const ResourceSelector = ({ selectedTab }) => {
  return (
    <div className="px-[36px] flex gap-[16px] overflow-x-scroll lg:justify-center">
      <Link to={'/resources'}
        className={`${
          selectedTab === "Articles"
            ? "border-b-[2px] border-[#0259DB] text-[#0259DB]"
            : "text-[#666666]"
        } font-medium text-[16px] cursor-pointer w-fit py-[12px] px-[24px] whitespace-nowrap`}
      >
        Blog
      </Link>

      <Link to={'/resources/gallery'}
        className={`${
          selectedTab === "Gallery"
            ? "border-b-[2px] border-[#0259DB] text-[#0259DB]"
            : "text-[#666666]"
        } font-medium text-[16px] cursor-pointer w-fit py-[12px] px-[24px] whitespace-nowrap`}
      >
        Gallery
      </Link>
      <Link to={'/faqs'}
        className={`${
          selectedTab === "FAQ"
            ? "border-b-[2px] border-[#0259DB] text-[#0259DB]"
            : "text-[#666666]"
        } font-medium text-[16px] cursor-pointer w-fit py-[12px] px-[24px] whitespace-nowrap`}
      >
        FAQ's
      </Link>
    </div>
  );
};

export default ResourceSelector;
