import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import Registration from "./pages/Registration";
import store from "./store/store";
import { Provider, useDispatch, useSelector } from "react-redux";
import Home from "./pages/Home";
import Dashboard from "./pages/Dashboard";
import Logs from "./pages/Logs";
import Reports from "./pages/Reports";
import Users from "./pages/Users";
import UserDetails from "./pages/UserDetails";
import BuyPlot from "./pages/BuyPlot";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import ScrollToTop from "./components/ScrollToTop";
import Notifications from "./pages/Notifications";
import Profile from "./pages/Profile";
import SellPlot from "./pages/SellPlot";
// import Subscription from "./pages/Subscription";
// import Payment from "./pages/Payment";
import Library from "./pages/Library";
import { getOwnProfile } from "./apis/apiClient";
import { logoutUser, updateUser } from "./reducers/profile";
import Plot from "./pages/Plot";
import NotFound from "./pages/NotFound";
import { socket } from "./socket";
import TermsConditions from "./pages/TermsConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import CancellationPolicy from "./pages/CancellationPolicy";
import SubscriptionPage from "./pages/SubscriptionPage";
import PaymentPage from "./pages/PaymentPage";
import Checkout from "./pages/Checkout";
import Blog from "./pages/Blog";
import FAQ from "./pages/FAQ";
import Gallery from "./pages/Gallery";
import Resources from "./pages/Resources";
import FAQDetails from "./components/Library/FAQDetails";
import BlogDetails from "./components/Library/BlogDetails";
import PaymentStatus from "./pages/PaymentStatus";
import PaymentInvalid from "./pages/PaymentInvalid";
import { addNotification } from "./reducers/notification";

function App() {
  const profile = useSelector((state) => state.profile);
  const dispatch = useDispatch();

  useEffect(() => {
    const getUserData = async () => {
      try {
        const response = await getOwnProfile();
        dispatch(updateUser(response));
      } catch (e) {
        if (e.response.status === 401) {
          dispatch(logoutUser());
        }
      }
    };
    if (profile.isLoggedIn && Object.keys(profile.userData).length === 0) {
      getUserData();
    }
  }, [profile]);

  useEffect(() => {
    if (profile.userData._id !== undefined) {
      socket.connect();
      socket.on("connect", () => {
        console.log("connected");
      });
      socket.on("disconnect", () => {
        console.log("disconnected");
      });
      socket.emit("join", { id: profile.userData._id });
      socket.on("notification", (data) => {
        console.log(`New notification:`, data);
      });
    }
  }, [profile]);
  useEffect(() => {
    // Listen for the 'adminDetailUpdate' event
    socket.on('adminDetailUpdate', (data) => {
      dispatch(addNotification({ message: data.message, type: 'adminUpdate' }));
    });

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      socket.off('adminDetailUpdate');
    };
  }, [dispatch, socket]);


  useEffect(() => {
    // Listen for the 'adminDetailUpdate' event
    socket.on('offerPlaced', (data) => {
      console.log("Yes DATA is coming",data)
      dispatch(addNotification({ message: data.message, type: 'offerPlaced' }));
    });

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      socket.off('offerPlaced');
    };
  }, [dispatch, socket]);

  useEffect(() => {
    // Listen for the 'adminDetailUpdate' event
    socket.on('kycUpdate', (data) => {
      dispatch(addNotification({ message: data.message, type: 'kycUpdate' }));
    });

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      socket.off('kycUpdate');
    };
  }, [dispatch, socket]);
  

  return (
    <GoogleOAuthProvider clientId="467650654241-kt7nu3tfelptnnq27qnjhp4m6glp7f9f.apps.googleusercontent.com">
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="*" element={<NotFound />} />

          <Route exact path="/" element={<Home />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/registration" element={<Registration />} />
          <Route exact path="/profile" element={<Profile />} />

          <Route exact path="/notifications" element={<Notifications />} />

          {profile.isLoggedIn && profile.userData.role === "admin" && (
            <>
              <Route exact path="/dashboard" element={<Dashboard />} />
              <Route exact path="/logs" element={<Logs />} />
              <Route exact path="/users" element={<Users />} />
              <Route exact path="/users/:userId" element={<UserDetails />} />
              <Route exact path="/reports" element={<Reports />} />
            </>
          )}

          <Route exact path="/buyplot" element={<BuyPlot />} />
          <Route exact path="/plot/:plotId" element={<Plot />} />

          
          <Route exact path="/sellplot" element={<SellPlot />} />
          <Route exact path="/editplot/:plotId" element={<SellPlot />} />

          {/* <Route exact path="/subscription" element={<SubscriptionPage />} /> */}
          
          {/* <Route exact path="/payment" element={<Payment />} /> */}
          <Route exact path="/payment/:id" element={<PaymentPage />} />
          <Route exact path="/checkout" element={<Checkout />} />
          <Route exact path="/payment-status" element={<PaymentStatus />} />
          <Route exact path="/payment-invalid" element={<PaymentInvalid />} />

          {/* <Route exact path="/transaction-st" element={<TransactionStatus />} /> */}



            
          <Route exact path="/resources" element={<Resources />} />

          <Route exact path="/blog" element={<Blog />} />
          <Route exact path="/blog/:articleName" element={<BlogDetails />} />
          <Route exact path="/faqs" element={<FAQ />} />
          <Route exact path="/faqs/:articleName" element={<FAQDetails />} />


          
          <Route exact path="/resources/gallery" element={<Gallery />} />
          {/* <Route exact path="/resource" element={<Resources />} /> */}




          <Route exact path="/terms&conditions" element={<TermsConditions />} />
          <Route exact path="/privacy" element={<PrivacyPolicy />} />
          <Route exact path="/cancellationpolicy" element={<CancellationPolicy />} />



        </Routes>

      </Router>
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </GoogleOAuthProvider>
  );
}

export default App;
