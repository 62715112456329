import { createSlice } from "@reduxjs/toolkit";

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState: {
    list: [],
  },
  reducers: {
    addNotification: (state, action) => {
      state.list = [...state.list, action.payload];
    },
  },
});

export const { addNotification } = notificationsSlice.actions;

export default notificationsSlice.reducer;