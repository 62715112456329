import React, { useEffect } from 'react'
import processing from "../assets/svg/payment/processing.png";
import HomeHeader from '../components/Header/HomeHeader';



const Checkout = () => {


  return (
    <>
    <HomeHeader isHome={false} />
    <div className="w-full flex flex-col justify-center items-center h-[calc(100vh-300px)]">
    <p className="text-[#635E61] text-[14px]">
      Request will timeout in 3:45 minutes
    </p>
    <h3 className="text-[#211A1E] text-[20px] font-medium mt-[16px]">
      Open your UPI app now to to complete the payment
    </h3>
    <h3 className="text-[#211A1E] text-[20px] font-medium mt-[16px]">
      Do not close or refresh this page
    </h3>
    <img
      src={processing}
      alt="paymentpeocession"
      className="my-[48px]"
    />
    <p className="text-[14px] font-semibold text-[#367DE7] w-[450px] text-center">
      Payment Request of ₹1756 has been sent to Ramkrushna@okicici from
      upi.rzp@hdfcbank
    </p>
  </div>

  </>
  )
}

export default Checkout