import React from "react";
import HomeHeader from "../components/Header/HomeHeader";
import Footer from "../components/Footer";

const CancellationPolicy = () => {
  return (
    <>
      <div className="bg-white mx-auto max-w-screen-xl px-[24px]">
        <HomeHeader isHome={false} />
        <div className="my-8 p-8 bg-white">
    <h2 className="text-3xl font-bold mb-6">Cancellation Policy</h2>

    <div className="text-lg font-semibold mb-4">Cancellation And Refund</div>

    <ol className="my-4 list-decimal ml-6 leading-7">
        <li className="mb-3">Cancellations are permitted only before the cut-off time (1 hour) after placing the listing request.</li>
        <li className="mb-3">No cancellation of the listing service will be possible post publishing.</li>
        <li className="mb-3">Rempo Ventures Pvt Ltd will not be liable for any discrepancy in the listing from the user's end.</li>
        <li className="mb-3">In any case of cancellations due to unforeseen circumstances, the payment amount will be refunded to the source account.</li>
        <li className="mb-3">If Rempo Ventures Pvt Ltd suspects any fraudulent transaction or transactions violating the terms of website use, Rempo Ventures Pvt Ltd has the sole discretion to cancel such listings. Further, such accounts/customers will be denied access to using the website.</li>
        <li>Contact our customer care for any further information or queries at <a href="mailto:info@rempo.in" className="text-blue-500 hover:underline">info@rempo.in</a> or call us on <a href="tel:+917020111767" className="text-blue-500 hover:underline">+91 7020111767</a>.</li>
    </ol>
</div>


      </div>
      <Footer />
    </>
  );
};

export default CancellationPolicy;
