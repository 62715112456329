import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import HomeHeader from "../components/Header/HomeHeader";

import qrcode from "../assets/svg/payment/qrcode.png";
import { useDispatch, useSelector } from "react-redux";
import { PaymentHDFC, getPayment } from "../apis/apiClient";
import axios from "axios";
import { baseURL } from "../constants";
import { setSubscriptionData } from "../reducers/profile";

const bankNames = [
  "Allahabad Bank",
  "Andhra Bank",
  "Axis Bank",
  "Bank of Bahrain and Kuwait",
  "Bank of Baroda - Corporate Banking",
  "Bank of Baroda - Retail Banking",
  "Bank of India",
  "Bank of Maharashtra",
  "Canara Bank",
  "Central Bank of India",
  "City Union Bank",
  "Corporation Bank",
  "Deutsche Bank",
  "Development Credit Bank",
  "Dhanlaxmi Bank",
  "Federal Bank",
  "ICICI Bank",
  "IDBI Bank",
  "Indian Bank",
  "Indian Overseas Bank",
  "IndusInd Bank",
  "ING Vysya Bank",
  "Jammu and Kashmir Bank",
  "Karnataka Bank Ltd",
  "Karur Vysya Bank",
  "Kotak Bank",
  "Laxmi Vilas Bank",
  "Oriental Bank of Commerce",
  "Punjab National Bank - Corporate Banking",
  "Punjab National Bank - Retail Banking",
  "Punjab & Sind Bank",
  "Shamrao Vitthal Co-operative Bank",
  "South Indian Bank",
  "State Bank of Bikaner & Jaipur",
  "State Bank of Hyderabad",
  "State Bank of India",
  "State Bank of Mysore",
  "State Bank of Patiala",
  "State Bank of Travancore",
  "Syndicate Bank",
  "Tamilnad Mercantile Bank Ltd.",
  "UCO Bank",
  "Union Bank of India",
  "United Bank of India",
  "Vijaya Bank",
  "Yes Bank Ltd",
];

const PaymentPage = () => {
  const [selectedMethod, setSelectedMethod] = useState("");
  const [step, setStep] = useState(1);
  const [isBanksModalOpen, setIsBanksModalOpen] = useState(false);
  const [isQRVisible, setIsQRVisible] = useState(false);
  const [data, setData] = useState({});

  let { id } = useParams();
  
  const profile = useSelector((state) => state.profile);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!profile.isLoggedIn) {
      navigate("/login", { state: { navigateTo: "/payment" } });
    }
  }, []);

  const paymentData = useSelector((state) => state.profile.subscriptionData);

  useEffect(() => {
    // Use Redux state to set the local data state
    if (id === "1" && paymentData) {
      setData({
        plan: paymentData.plan,
        paymentAmt: paymentData.paymentAmt,
        gst: paymentData.gst,
        TotalAmt: paymentData.totalAmt,
        actualPrice: paymentData.actualPrice
      });
    } else if (id === "2" && paymentData) {
      setData({
        plan: paymentData.plan,
        paymentAmt: paymentData.paymentAmt,
        numberOfPlot : paymentData.numberOfPlot,
        gst: paymentData.gst,
        TotalAmt: paymentData.totalAmt + paymentData.gst,
        actualPrice: paymentData.actualPrice
      });
    } else {
      navigate("/subscription");
    }
  }, [id, navigate, paymentData]);


  console.log("data",data)



  const handleSubmit = async (e) => {
    e.preventDefault();
    const currentDate = new Date();
    const timestamp = currentDate.getTime();
    const uniqueValue = timestamp.toString().slice(-7);

    const randValue = Date.now()


   
    const transactionUser = profile.userData

    // const cancelUrl = "http://localhost:3000/subscription"
    const cancelUrl = `${baseURL}/payment/responseHandle`

    const redirectUrl = `${baseURL}/payment/responseHandle`

    const uniqueOrderId = `R-${uniqueValue}-${Math.floor(
      Math.random() * 1000
    )}`;


    const formData = `order_id=${uniqueOrderId}&currency=INR&amount=${data?.TotalAmt}&redirect_url=${redirectUrl}&cancel_url=${cancelUrl}&merchant_param1=${transactionUser._id}&merchant_param2=${data.plan}&merchant_param3=${data.paymentAmt}&merchant_param4=${data?.numberOfPlot || 1}&tid=${randValue}&language=EN`;

    try {
      const response = await axios.post(
        `${baseURL}/payment/ccavRequestHandler`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log(response.data)

      const formContainer = document.createElement("div");
      formContainer.innerHTML = response.data;
      const form = formContainer.querySelector("form");
      document.body.appendChild(form);
      form.submit();

    

      // You can handle the server response as needed
    } catch (error) {
      console.error("Error submitting form:", error);
    }
    
  };

  return (
    <div className="bg-white mx-auto max-w-screen-xl px-[24px]">
      <HomeHeader isHome={false} />
      <div className="mt-[24px]">
        <div className="flex items-center gap-[20px]">
          <Icon icon="bx:arrow-back" color="#2B2B2B" width={24} />
          <h3 className="text-[#000000] text-[24px] font-semibold">Payment</h3>
        </div>
        <div className="text-center gap-[40px] my-[32px]">
  <div className="flex justify-center">
    <div className="w-full md:w-1/2 rounded-regular border-[1px] border-[#D9D9D9] p-[24px] h-fit">
              <div className="flex justify-between items-center">
                <p className="text-[16px] text-[#000E14]">Plan Selected:</p>
                <p className="text-[16px] text-[#000E14]">{data?.plan}</p>
              </div>
              <div className="flex justify-between items-center mt-[8px]">
                <p className="text-[16px] text-[#000E14]">Payment Amount:</p>
                <p className="text-[16px] text-[#000E14] flex items-center">
                  <Icon icon="material-symbols:currency-rupee" />
                  {data?.paymentAmt} {data?.numberOfPlot && '*' } {data?.numberOfPlot}
                </p>
              </div>
              <div className="flex justify-between items-center mt-[8px]">
                <p className="text-[16px] text-[#000E14]">GST</p>
                <p className="text-[16px] text-[#000E14] flex items-center">
                  <Icon icon="material-symbols:currency-rupee" />
                  {data?.gst}
                </p>
              </div>
              <div className="flex justify-between items-center mt-[12px]">
                <p className="text-[18px] text-[#000E14] font-bold">
                  Total Amount:
                </p>
                <p className="text-[18px] text-[#000E14] font-bold flex items-center gap-2">
                  <span className="flex items-center ">

                  <Icon icon="material-symbols:currency-rupee" />
                  {parseInt(data?.TotalAmt)}
                  </span>
                   <span className="flex items-center text-sm text-gray-600 font-light line-through">₹{parseInt(data?.actualPrice)}</span>
                </p>
              </div>
              <form method="POST" onClick={handleSubmit}>
                <button
                  type="submit"
                  className="p-[12px] border-[1px] border-[#0259DB] rounded-regular w-full text-[16px] font-bold text-white bg-[#0259DB] mt-[24px]"
                >
                  Proceed to pay
                </button>
              </form>
            </div>
        
          </div>
        </div>
      </div>

     
    </div>
  );
};

export default PaymentPage;
