import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import Footer from "../components/Footer";
import "leaflet/dist/leaflet.css";
import ImageSlider from "../components/Plot/ImageSlider";
import BidsModal from "../components/Plot/BidsModal/BidsModal";
import HomeHeader from "../components/Header/HomeHeader";
import { getBids, getPlotByID } from "../apis/apiClient";
import { useSelector } from "react-redux";
import { baseURL } from "../constants";
import PlotMainDetails from "../components/Plot/PlotMainDetails";
import Insights from "../components/Plot/Insights";
import Highlights from "../components/Plot/Highlights";
import ImageModal from "../components/Plot/ImageModal";
import DocsModal from "../components/Plot/DocsModal";
import LocationDetails from "../components/Plot/LocationDetails";
import DocumentDetails from "../components/Plot/DocumentDetails";
import LegalDetails from "../components/Plot/LegalDetails";
import PlotDetails from "../components/Plot/PlotDetails";
import { ThreeDots } from "react-loader-spinner";
import lightImage from "../assets/svg/image_black.svg";
import backImage from "../assets/svg/arrowleft.svg";
import InterestedModal from "../components/Plot/InterestedModal/InterestedModal";

const Plot = () => {
  const { plotId } = useParams();
  const [selectedPlot, setSelectedPlot] = useState({
    label: "Please select plot from the list",
    value: {},
  });
  const [plotDetails, setPlotDetails] = useState({});
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [isLightBoxOpen, setIsLightBoxOpen] = useState(false);
  const [isBidsModalOpen, setIsBidsModalOpen] = useState(false);
  const [isInterestedModalOpen, setIsInterestedModalOpen] = useState(false);

  const [photoIndex, setPhotoIndex] = useState(0);
  const [selectedDocument, setSelectedDocument] = useState([]);
  const [isDocViewerOpen, setIsDocViewerOpen] = useState(false);
  const [schemeInsights, setSchemeInsights] = useState({});
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isLoading, setIsLoading] = useState(true);

  const profile = useSelector((state) => state.profile);
  const navigate = useNavigate();

  useEffect(() => {
    if (!profile.isLoggedIn) {
      navigate("/login", { state: { navigateTo: window.location.pathname } });
    }
  }, []);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWindowWidth(window.innerWidth);
    });
  }, []);

  useEffect(() => {
    const fetchPlotData = async () => {
      try {
        if (plotDetails?.type === "single") {
          setIsLoading(true);
        }
        const response = await getPlotByID({
          plotId,
        });
        setIsLoading(false);

        const insights = {
          bids: [],
          views: [],
          interested: [],
          contacts_fetched: [],
        };

        if (response.type === "bulk") {
          if (response.scheme) {
            delete response.views;
            delete response.contacts_fetched;
            delete response.interested;
            delete response.bids;

            delete response.scheme?.id;
            delete response.scheme?.puid;
            // delete response.scheme?.;

            response.scheme?.plots?.map((plot, index) => {
              if (response.puid === plot.puid) {
                setSelectedPlot({
                  label: plot.plot_name,
                  value: { ...plot },
                });
              }
              insights.bids = [...insights.bids, ...plot.bids];
              insights.interested = [
                ...insights.interested,
                ...plot.interested,
              ];
              insights.views = [...insights.views, ...plot.views];
              insights.contacts_fetched = [
                ...insights.contacts_fetched,
                ...plot.contacts_fetched,
              ];
              return true;
            });

            setPlotDetails((prevState) => ({
              ...prevState,
              ...response,
              ...response.scheme,
              ...insights,
            }));
          } else {
            const images = [];

            response.plots?.map((plot) => {
              plot.images?.map((image) => {
                images.push(image);
                return "";
              });

              insights.bids = [...insights.bids, ...plot.bids];
              insights.interested = [
                ...insights.interested,
                ...plot.interested,
              ];
              insights.views = [...insights.views, ...plot.views];
              insights.contacts_fetched = [
                ...insights.contacts_fetched,
                ...plot.contacts_fetched,
              ];

              return "";
            });

            setSchemeInsights(insights);

            setPlotDetails((prevState) => ({
              ...prevState,
              ...response,
              images,
              ...insights,
            }));
          }
          if (response.scheme) {
            const bids = await getBids({
              plotId: [response.id],
            });
            setPlotDetails((prevState) => ({ ...prevState, bids }));
          } else {
            const bids = await getBids({
              plotId: response.plots?.map((plot) => plot.id),
            });
            setPlotDetails((prevState) => ({ ...prevState, bids }));
          }
        } else {
          // const bids = await getBids({ plotId: response.id });
          setPlotDetails({ ...response });

          const bids = await getBids({
            plotId: [response.id],
          });
          setPlotDetails((prevState) => ({ ...prevState, bids }));
        }
      } catch (e) {
        setIsLoading(false);
        console.log(e);
      }
    };
    fetchPlotData();
  }, [plotId]);

  useEffect(() => {
    if (selectedPlot.value?.puid && selectedPlot.value?.puid !== plotId) {
      navigate(`/plot/${selectedPlot.value?.puid}`);
    }
  }, [selectedPlot, navigate, plotId]);

  const handleViewBids = async () => {
    if (
      plotDetails?.listed_by?.id === profile.userData?.id ||
      profile.userData?.role === "admin"
    ) {
      if (
        plotDetails?.bids?.length > 0 ||
        selectedPlot.value?.bids?.length > 0
      ) {
        if (plotDetails?.type === "single") {
          const response = await getBids({
            plotId: [plotDetails?.id],
          });
          setPlotDetails((prevState) => ({ ...prevState, bids: response }));
        } else {
          if (selectedPlot.value?.puid) {
            const response = await getBids({
              plotId: [plotDetails?.id],
            });
            setPlotDetails((prevState) => ({ ...prevState, bids: response }));
          } else {
            const response = await getBids({
              plotId: plotDetails?.plots?.map((plot) => plot.id),
            });
            setPlotDetails((prevState) => ({ ...prevState, bids: response }));
          }
        }
        setIsBidsModalOpen(true);
      }
    }
  };

  return (
    <>
      <div className="bg-white mx-auto max-w-screen-xl px-[24px]">
        <HomeHeader />

        {isLoading ? (
          <div className="w-full min-h-[50vh] flex justify-center items-center ">
            <ThreeDots
              height="80"
              width="80"
              radius="9"
              color="#367DE7"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={isLoading}
            />
          </div>
        ) : (
          <>
            <div className="flex flex-col-reverse lg:flex-row w-full gap-[16px] justify-evenly mt-[24px]">
              <PlotMainDetails
                plotDetails={plotDetails}
                setSelectedPlot={setSelectedPlot}
                selectedPlot={selectedPlot}
                handleViewBids={handleViewBids}
              />
              <ImageSlider
                setIsImageModalOpen={setIsImageModalOpen}
                data={plotDetails?.images}
                videos={plotDetails?.videos ? plotDetails?.videos : []}
              />
            </div>

            <div className="mt-[48px]">
              <div className="flex justify-between">
                <h3 className="text-[24px] text-[#000E14] font-semibold">
                  Property Description
                </h3>
                {/* {(plotDetails?.listed_by === profile.userData?.id ||
              profile.userData?.role === "admin") && (
              <button className="text-[16px] text-primaryColor font-semibold">
                Edit Details
              </button>
            )} */}
              </div>
              <div className="shadow-md px-[24px] py-[12px] w-full rounded-regular mt-[12px]">
                <p className="text-[16px] text-[#666666] break-all">
                  {plotDetails?.description}
                </p>
              </div>
            </div>

            {(plotDetails?.listed_by?.id === profile.userData?.id ||
              profile.userData?.role === "admin") && (
              <Insights
                setIsInterestedModalOpen={setIsInterestedModalOpen}
                plotDetails={plotDetails}
                selectedPlot={selectedPlot}
              />
            )}

            {plotDetails?.type === "single" && (
              <PlotDetails plotDetails={plotDetails} />
            )}

            {plotDetails?.features?.length > 0 && (
              <div className="mt-[32px]">
                <div className="flex justify-between">
                  <h3 className="text-[24px] text-[#000E14] font-semibold">
                    Property Landmark
                  </h3>
                  {/* {(plotDetails?.listed_by === profile.userData?.id ||
              profile.userData?.role === "admin") && (
              <button className="text-[16px] text-primaryColor font-semibold">
                Edit Details
              </button>
            )} */}
                </div>
                <div className="shadow-md px-[24px] py-[12px] w-full rounded-regular mt-[12px] grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-[16px]">
                  {plotDetails?.features?.map((feature) => (
                    <div className="w-full min-h-[84px] py-[8px] px-[16px] border-[1px] border-[#D9D9D9] rounded-regular">
                      <h6 className="text-[24px] text-[#000E14] font-medium">
                        {feature.name}
                      </h6>
                      <h5 className="mt-[8px] text-[16px] text-[#838383] font-semibold">
                        {feature.distance &&
                          feature.distance?.trim !== "" &&
                          `Distance: ${feature.distance}`}
                      </h5>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {plotDetails?.type === "single" && (
              <Highlights
                plotDetails={plotDetails}
                selectedPlot={selectedPlot}
              />
            )}

            <LegalDetails plotDetails={plotDetails} />

            {plotDetails?.type === "bulk" && (
                <div
                  style={{
                    marginLeft: -((windowWidth - 1280 + 48) / 2),
                    marginRight: -((windowWidth - 1280 + 48) / 2),
                   
                  }}
                  className="mt-[32px]"
                >
                  <div className="p-[24px] max-w-screen-xl mx-auto">
                    {plotDetails && (
                      <PlotDetails
                        plotDetails={plotDetails}
                        selectedPlot={selectedPlot}
                      />
                    )}
                    {plotDetails?.highlights?.length > 0 ? (

                      <Highlights
                      plotDetails={plotDetails}
                      selectedPlot={selectedPlot}
                      />
                    ) : null}
                  </div>
                </div>
              )}
            {plotDetails?.documents?.length > 0 && (
              <DocumentDetails
                plotDetails={plotDetails}
                setSelectedDocument={setSelectedDocument}
                setIsDocViewerOpen={setIsDocViewerOpen}
              />
            )}
            <LocationDetails plotDetails={plotDetails} />
          </>
        )}
      </div>

      <ImageModal
        isImageModalOpen={isImageModalOpen}
        setIsImageModalOpen={setIsImageModalOpen}
        plotDetails={plotDetails}
        setIsLightBoxOpen={setIsLightBoxOpen}
        setPhotoIndex={setPhotoIndex}
      />

      <BidsModal
        isBidsModalOpen={isBidsModalOpen}
        setIsBidsModalOpen={setIsBidsModalOpen}
        bids={plotDetails?.bids}
      />

      <InterestedModal
        isInterestedModalOpen={isInterestedModalOpen}
        setIsInterestedModalOpen={setIsInterestedModalOpen}
        interested={plotDetails?.interested}
        plotId={plotDetails?.id}
      />

      {isLightBoxOpen && (
        <Lightbox
          // toolbarButtons={[
          //   <button className="absolute top-[24px] md:top-[96px] right-[24px] md:right-[96px]">
          //     <Icon icon="material-symbols:share" width={28} color="#fff" />
          //   </button>,
          // ]}
          mainSrc={`${baseURL}/${plotDetails?.images[photoIndex]?.path}`}
          nextSrc={`${baseURL}/${
            plotDetails?.images[(photoIndex + 1) % plotDetails?.images?.length]
              ?.path
          }`}
          prevSrc={`${baseURL}/${
            plotDetails?.images[(photoIndex - 1) % plotDetails?.images?.length]
              ?.path
          }`}
          onCloseRequest={() => {
            setIsImageModalOpen(true);
            setIsLightBoxOpen(false);
          }}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (prevState) =>
                (prevState + (plotDetails?.images?.length - 1)) %
                plotDetails?.images?.length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex(
              (prevState) => (prevState + 1) % plotDetails?.images?.length
            )
          }
          imageTitle={
            <>
              <img
                className="cursor-pointer"
                src={backImage}
                alt=""
                onClick={() => {
                  setIsLightBoxOpen(false);
                }}
              />

              <img src={lightImage} alt="" />
              {plotDetails?.images[photoIndex]?.originalname}
            </>
          }
        />
      )}

      <DocsModal
        isDocViewerOpen={isDocViewerOpen}
        setIsDocViewerOpen={setIsDocViewerOpen}
        selectedDocument={selectedDocument}
      />

      <Footer />
    </>
  );
};

export default Plot;
