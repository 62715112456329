import { Icon } from "@iconify/react";
import React, { useEffect, useReducer, useRef, useState } from "react";
import { Link, useFetcher } from "react-router-dom";
import logo from "../assets/svg/logo.svg";
import PlotCard from "../components/BuyPlot/PlotCard";
import InfiniteScroll from "react-infinite-scroll-component";
import BuyerHeader from "../components/Header/BuyerHeader";
import HomeHeader from "../components/Header/HomeHeader";
import { fetchPlots } from "../apis/apiClient";
import { useSelector } from "react-redux";
import { MagnifyingGlass } from "react-loader-spinner";
import axios from "axios";
import { showToast } from "../utils/showToast";
import GoogleMapReact from "google-map-react";
import marker from "../assets/images/mapmarker.png";

const loadScript = (url, callback, errorCallback) => {
  let script = document.createElement("script");
  script.type = "text/javascript";

  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
    script.onerror = () => errorCallback();
  }

  script.src = url;
  document.head.appendChild(script);
};

const BuyPlot = () => {
  const [results, setResults] = useState({
    totalPages: 0,
    page: 0,
    totalResults: 0,
  });
  const [plotData, setPlotData] = useState([]);
  const [isResultsLoading, setIsResultsLoading] = useState(false);
  const [isScrollToTopVisible, setIsScrollToTopVisible] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedLocation, setSelectedLocation] = useState({});

  const [location, setLocation] = useState({});
  const [locationList, setLocationList] = useState([]);
  const [price, setPrice] = useState("");
  const [size, setSize] = useState("");
  const [type, setType] = useState("");

  const [page, setPage] = useState(1);
  const [locater, setlocater] = useState({ lat: 19.7515, lng: 75.7139 });
  const [ShowMap, setShowMap] = useState(false);

  const profile = useSelector((state) => state.profile);
  let autoComplete;

  const autoCompleteRef = useRef(null);

  const fetchPlotsbyFilter = async (isFirstLoad) => {
    let newLocation = selectedLocation;

    if (search.length > 0 && Object.keys(selectedLocation).length === 0) {
      const autocompleteService =
        new window.google.maps.places.AutocompleteService();

      autocompleteService.getPlacePredictions(
        { input: search },
        async (predictions, status) => {
          if (
            status === window.google.maps.places.PlacesServiceStatus.OK &&
            predictions.length > 0
          ) {
            setSearch(predictions[0].description);

            const placesService = new window.google.maps.places.PlacesService(
              document.createElement("div")
            );
            placesService.getDetails(
              { placeId: predictions[0].place_id },
              async (place, detailsStatus) => {
                if (
                  detailsStatus ===
                  window.google.maps.places.PlacesServiceStatus.OK
                ) {
                  newLocation = {
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng(),
                  };

                  await setSelectedLocation(newLocation);
                  await setlocater(newLocation);
                }
                await fetchPlotsData(newLocation, isFirstLoad, search);
              }
            );
          }
        }
      );
    } else {
      await fetchPlotsData(selectedLocation, isFirstLoad, search);
    }
  };

  const fetchPlotsData = async (location, isFirstLoad, search) => {
    console.log("search", search)
    const data = {
      location:
        Object.keys(location).length > 0
          ? Object.values(location).join(",")
          : encodeURIComponent(search),
      price,
      size,
      type,
      page,
      limit: 10,
    };
  
    try {
      if (isFirstLoad) {
        setIsResultsLoading(true);
      }
      const response = await fetchPlots(data, search);
      console.log("The Response",response)
  
      if (isFirstLoad) {
        setIsResultsLoading(false);
      }
  
      if (response && response.results) {
        setResults(response);
        setPlotData((prevState) => [...prevState, ...response.results]);
      } else {
        showToast("Error: No results found.");
      }
    } catch (error) {
      console.error("Error fetching plots:", error);
  
      // Handle network errors or unexpected issues
      showToast("An unexpected error occurred. Please try again later.");
  
      setIsResultsLoading(false);
    }
  };
  

  useEffect(() => {
    const scriptUrl = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAe9PS63RZNSnCAVaiOCGYWePAtq3oGHSc&libraries=places`;

    const handleScriptError = () => {
      console.error("Error loading Google Maps script.");
    };

    loadScript(
      scriptUrl,
      () => handleScriptLoad(setSearch, autoCompleteRef),
      handleScriptError
    );
    handlegetLocation();
  }, [setSearch, autoCompleteRef]);

  useEffect(() => {
    fetchPlotsbyFilter(page === 1);
  }, [page]);

  function handleScriptLoad(updateQuery, autoCompleteRef) {
    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current,
      { componentRestrictions: { country: "in" } }
    );
    autoComplete.addListener("place_changed", () =>
      handlePlaceSelect(updateQuery)
    );
  }

  async function handlePlaceSelect(updateQuery) {
    const addressObject = autoComplete.getPlace();
    
    const query = addressObject.formatted_address;
    console.log("addressObject", addressObject, query)
    if (addressObject.geometry?.location) {
      updateQuery(query);
      setSelectedLocation({
        lat: addressObject.geometry.location.lat(),
        lng: addressObject.geometry.location.lng(),
      });
      setlocater({
        lat: addressObject.geometry.location.lat(),
        lng: addressObject.geometry.location.lng(),
      });
    }
  }

  const handlegetLocation = () => {
    navigator.geolocation.getCurrentPosition(
      async function (res) {
        try {
          const response = await axios.get(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${res.coords.latitude},${res.coords.longitude}&key=AIzaSyAe9PS63RZNSnCAVaiOCGYWePAtq3oGHSc`
          );
          setSelectedLocation({
            lat: res.coords.latitude,
            lng: res.coords.longitude,
          });
          setlocater({
            lat: res.coords.latitude,
            lng: res.coords.longitude,
          });
          setSearch(response.data.results[0]?.formatted_address);
        } catch (error) {
          console.log(error);
        }
      },
      function (e) {
        console.log(e);
      }
    );
  };

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setIsScrollToTopVisible(true);
    } else if (scrolled <= 300) {
      setIsScrollToTopVisible(false);
    }
  };

  window.addEventListener("scroll", toggleVisible);

  const [selectedMarker, setSelectedMarker] = useState(null);

  const locations = plotData
    .map((item, index) => {
      const lat = item.location?.latlng?.[0];
      const lng = item.location?.latlng?.[1];
      return {
        id: index + 1,
        lat: lat,
        lng: lng,
      };
    })
    .filter(
      (location) => location.lat !== undefined && location.lng !== undefined
    );

  const [showGmapPlotCard, setShowGmapPlotCard] = useState(false);

  const handleShowGmapPlotCard = (value) => {
    setShowGmapPlotCard(value);
  };

  const handleMarkerClick = (location) => {
    setSelectedMarker(location.id);
    setShowGmapPlotCard(true);
    setlocater({
      lat: parseFloat(location.lat),
      lng: parseFloat(location.lng),
    });
  };

  return (
    <div className="bg-white px-[24px]">
      <HomeHeader isHome={false} className="mx-auto max-w-screen-xl h-[10px]" />
      <div className="mt-[20px] bg-[#F6F6F6] py-[16px] px-[24px] flex flex-col lg:flex-row gap-[16px] rounded-regular mx-auto max-w-screen-xl">
        <div className="px-[12px] py-[10px] border-[1px] border-[#D0D5DD] bg-white rounded-regular flex items-center w-full lg:max-w-[450px] relative">
          <Icon
            icon="material-symbols:search"
            color="#858585"
            width="28"
            height="28"
          />
          <input
            ref={autoCompleteRef}
            value={search}
            onChange={async (e) => {
              setSelectedLocation({});
              setSearch(e.target.value);
            }}
            onKeyDown={async (e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                const autocompleteService =
                  new window.google.maps.places.AutocompleteService();
                autocompleteService.getPlacePredictions(
                  { input: search },
                  (predictions, status) => {
                    if (
                      status ===
                        window.google.maps.places.PlacesServiceStatus.OK &&
                      predictions.length > 0
                    ) {
                      const firstOption = predictions[0];
                      const query = firstOption.description;
                      const placeId = firstOption.place_id;

                      const placesService =
                        new window.google.maps.places.PlacesService(
                          document.createElement("div")
                        );
                      placesService.getDetails(
                        { placeId: placeId },
                        async (place, detailsStatus) => {
                          if (
                            detailsStatus ===
                            window.google.maps.places.PlacesServiceStatus.OK
                          ) {
                            const newLocation = {
                              lat: place.geometry.location.lat(),
                              lng: place.geometry.location.lng(),
                            };
                            setSelectedLocation(newLocation);
                            setlocater(newLocation);
                            setSearch(query);
                          }
                        }
                      );
                    }
                  }
                );
              }
            }}
            id="search"
            type="text"
            placeholder="Enter City or Village or Pin code"
            aria-label="Search Input"
            className="outline-none text-[16px] font-medium text-[#858585] ml-[12px] w-full pr-[16px]"
          />
          <Icon
            onClick={handlegetLocation}
            className="text-[#858585] hover:text-[#367DE7] cursor-pointer"
            icon="material-symbols:my-location-rounded"
            width="28"
            height="28"
          />
        </div>

        <div className="border-[1px] pr-[12px] border-[#D0D5DD] bg-white rounded-regular flex flex-row-reverse items-center w-full lg:max-w-[220px]">
          <select
            value={type}
            onChange={(e) => {
              setType(e.target.value);
            }}
            placeholder="Property Type"
            className="px-[12px] py-[10px] outline-none text-[16px] font-medium text-[#858585] ml-[12px] w-full bg-transparent"
          >
            <option value="">Property Type</option>
            {[
              "NA",
              "Guntha",
              "Farmhouse",
              "Society",
              "Cidco",
              "MHADA",
              "Residential",
              "Commercial",
              "Industrial",
              "Residential cum Commercial",
              "Other",
            ].map((opt) => (
              <option value={opt}>{opt} Plot</option>
            ))}
          </select>
        </div>

        <div className="border-[1px] pr-[12px] border-[#D0D5DD] bg-white rounded-regular flex flex-row-reverse items-center w-full lg:max-w-[220px]">
          <select
            value={size}
            onChange={(e) => {
              setSize(e.target.value);
            }}
            className="px-[12px] py-[10px] outline-none text-[16px] font-medium text-[#858585] ml-[12px] w-full bg-transparent"
          >
            <option value="">Size of Property</option>
            <option value="0-1000">0 - 1000 Sq Ft</option>
            <option value="1000-2500">1000 to 2500 Sq Ft </option>
            <option value="2500-5000">2500 to 5000 Sq Ft </option>
            <option value="5000-10000">5000 to 10,000 Sq Ft </option>
            <option value="10000-9999999999999999999999999999999999999999999999999999999999999999999999999999999">
              above 10,000 Sq Ft{" "}
            </option>
          </select>
        </div>

        <div className="border-[1px] pr-[12px] border-[#D0D5DD] bg-white rounded-regular flex flex-row-reverse items-center w-full lg:max-w-[220px]">
          <select
            value={price}
            onChange={(e) => {
              setPrice(e.target.value);
            }}
            className="px-[12px] py-[10px] outline-none text-[16px] font-medium text-[#858585] ml-[12px] w-full bg-transparent"
          >
            <option value="">Price Range</option>
            <option value="0-1000000">Upto 10 lacs</option>
            <option value="1000000-2500000">10 lacs to 25 lacs</option>
            <option value="2500000-5000000">25 lacs to 50 lacs</option>
            <option value="5000000-10000000">50 lacs to 1 Cr</option>
            <option value="10000000-9999999999999999999999999999999999999999999999999999999999999999999999999999999">
              Above 1 Cr
            </option>
          </select>
        </div>

        <button
          className="rounded-regular bg-[#0259DB] p-[15px] text-[16px] text-white font-medium flex items-center justify-center"
          type="button"
          onClick={() => {
            setPlotData([]);
            if (page !== 1) {
              setPage(1);
            } else {
              fetchPlotsbyFilter(true);
            }
          }}
        >
          <Icon
            icon="material-symbols:search"
            color="#fff"
            width="28"
            height="28"
          />
        </button>
      </div>

      <main>
        <div className="flex justify-between flex-col xl:flex-row relative max-w-screen-xl mx-auto">
          <h1 className="mt-[24px] text-[20px] md:text-[24px] font-semibold text-[#013583] relative">
            Plots/Land Collections -{" "}
            <span className="text-[#367DE7] font-medium">
              {profile.isLoggedIn
                ? "Fetched based on your current Location"
                : "For better results please login"}
            </span>
          </h1>
          <div>
            <button
              className="bg-btnBgColor border-[2px] border-primaryColor px-[5px] py-[6px] text-primaryColor text-[16px] rounded-regular mt-4"
              onClick={() => {
                setShowMap(!ShowMap);
              }}
            >
              {ShowMap ? "Hide Map" : "Show Map"}
            </button>
          </div>
        </div>

        {isResultsLoading ? (
          <div className="w-full min-h-[400px] flex justify-center items-center">
            <MagnifyingGlass
              visible={isResultsLoading}
              height="80"
              width="80"
              ariaLabel="MagnifyingGlass-loading"
              wrapperStyle={{}}
              wrapperClass="MagnifyingGlass-wrapper"
              glassColor="#c0efff"
              color="#367DE7"
            />
          </div>
        ) : (
          <>
            {plotData.length === 0 ? (
              <h2 className="mt-[148px] text-center">
                No matches found. We will soon be there
              </h2>
            ) : (
              <div
                className={`md:w-full ${
                  ShowMap ? "" : "mx-auto max-w-screen-xl"
                }`}
              >
                <div className="md:flex md:gap-6">
                  {ShowMap && (
                    <div className="p-0 m-0!important mt-2 xl:mt-0 xl:-ml-5 h-[62vh] w-full flex justify-center xl:w-[55%]">
                      <GoogleMapReact
                        key={locations.length}
                        bootstrapURLKeys={{
                          key: "AIzaSyAe9PS63RZNSnCAVaiOCGYWePAtq3oGHSc",
                        }}
                        options={(maps) => {
                          return {
                            mapTypeControl: true,
                            mapTypeControlOptions: {
                              style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
                              position: maps.ControlPosition.BOTTOM_CENTER,
                              mapTypeIds: [
                                maps.MapTypeId.ROADMAP,
                                maps.MapTypeId.SATELLITE,
                                maps.MapTypeId.HYBRID,
                              ],
                            },
                            zoomControl: true,
                            clickableIcons: false,
                          };
                        }}
                        center={locater}
                        zoom={
                          selectedMarker !== null ||
                          Object.keys(selectedLocation).length > 0
                            ? 11.5
                            : 6.1
                        }
                        onClick={() => {
                          setSelectedMarker(null);
                        }}
                      >
                        {locations.map((location, index) => (
                          <div
                            key={location.id}
                            lat={location.lat}
                            lng={location.lng}
                            className={`marker-container ${
                              selectedMarker === location.id ? "active" : ""
                            }`}
                            onClick={() => handleMarkerClick(location)}
                            style={{ position: "relative" }}
                          >
                            <img
                              src={marker}
                              alt="map marker"
                              className="w-2 hover:scale-150 relative"
                            />
                            {selectedMarker === location.id && (
                              <div
                                className={`plot-card-container ${
                                  selectedMarker !== null ? "show" : ""
                                }`}
                                style={{
                                  position: "absolute",
                                  top: "-80px",
                                  left: "-50px",
                                }}
                              >
                                {showGmapPlotCard && (
                                  <PlotCard
                                    showGmapPlotCard={showGmapPlotCard}
                                    setShowGmapPlotCard={handleShowGmapPlotCard}
                                    data={plotData[selectedMarker - 1]}
                                    keyIndex={selectedMarker - 1}
                                    key={selectedMarker - 1}
                                  />
                                )}
                              </div>
                            )}
                          </div>
                        ))}
                      </GoogleMapReact>
                    </div>
                  )}
                  <div className="md:overflow-y-scroll md:max-h-[60vh]">
                    <InfiniteScroll
                      className={`${
                        ShowMap ? "lg:grid-cols-1" : "lg:grid-cols-2"
                      } mt-[24px] grid grid-cols-1 gap-[32px]`}
                      dataLength={plotData.length}
                      next={() => {
                        setPage((prevState) => prevState + 1);
                      }}
                      hasMore={results.totalPages > results.page}
                      loader={
                        <div className="w-full pb-[50px] sm:w-[calc(200%+32px)] flex flex-col justify-center items-center text-[16px] font-medium gap-[16px]">
                          <Icon
                            icon="material-symbols:autorenew"
                            className="animate-spin"
                            color="#666666"
                            width={24}
                            height={24}
                          />
                          <p>Loading properties</p>
                        </div>
                      }
                      scrollThreshold={1}
                    >
                      {plotData.map((data, index) => {
                        return (
                          <>
                            {(!data.listed_by?.[0]?.isBlocked ||
                              data.listed_by?.[0]?.isBlocked === "false") && (
                              <PlotCard
                                data={data}
                                keyIndex={index}
                                key={index}
                              />
                            )}
                          </>
                        );
                      })}
                    </InfiniteScroll>
                  </div>
                </div>
                {isScrollToTopVisible && (
                  <div
                    className="fixed w-[48px] h-[48px] bg-white rounded-full bottom-[36px] left-[50%] translate-x-[-50%] shadow-xl flex justify-center items-center cursor-pointer"
                    onClick={() => {
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                    }}
                  >
                    <Icon icon="material-symbols:arrow-upward" width={24} />
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </main>
    </div>
  );
};

export default BuyPlot;
