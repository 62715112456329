import React, { useEffect, useState } from "react";
import HomeHeader from "../components/Header/HomeHeader";
import AdminHeader from "../components/Header/AdminHeader";
import { useSelector } from "react-redux";
import { Icon } from "@iconify/react";
import { useLocation } from "react-router-dom";
import { fuzzyMatch } from "../utils/sharedFunc";
import { getLibrary } from "../apis/apiClient";
import Articles from "../components/Library/Articles";
import { baseURL } from "../constants";
import backImage from "../assets/svg/arrowleft.svg";
import lightImage from "../assets/svg/image_black.svg";
import Lightbox from "react-image-lightbox";
import ResourceSelector from "../components/Library/ResourceSelector";

const Gallery = ({ isResource }) => {
  const [listData, setListData] = useState([]);
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const [isAddNewOpen, setIsAddNewOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [libraryData, setLibraryData] = useState({});
  const [isLightBoxOpen, setIsLightBoxOpen] = useState(false);
  const [galleryImages, setGalleryImages] = useState([]);
  const [photoIndex, setPhotoIndex] = useState(0);

  let { state } = useLocation();
  const profile = useSelector((state) => state.profile);

  useEffect(() => {
    setListData(
      libraryData?.data?.locations.filter((article) =>
        fuzzyMatch(searchQuery.toLowerCase(), article.name.toLowerCase())
      )
    );
  }, [searchQuery, libraryData]);

  useEffect(() => {
    setListData(libraryData?.data?.locations);
  }, [libraryData]);

  return (
    <>
      {!isResource && (
        <>
          {profile.isLoggedIn && profile.userData.role === "admin" ? (
            <AdminHeader />
          ) : (
            <HomeHeader isHome={false} />
          )}
        </>
      )}
      <div className="bg-white mx-auto max-w-screen-xl px-[24px] mt-[24px]">
         <ResourceSelector selectedTab={'Gallery'}/>
          <div className="mt-[32px]">
            <div className="flex justify-between md:items-center flex-col gap-[20px] md;gap-0 md:flex-row">
              <div>
                <h1 className="text-[32px] text-[#000E14] font-medium">
                  Gallery
                </h1>
              </div>
              <div className="flex gap-[16px] min-w-fit">
                {profile.isLoggedIn &&
                  profile.userData.role ===
                    "admin" && (
                      <button
                        onClick={() => {
                          setIsAddNewOpen(true);
                        }}
                        className="py-[8px] px-[16px] w-fit border-[1px] border-[#0259DB] rounded-regular text-[16px] font-medium text-white bg-[#0259DB] whitespace-nowrap"
                      >
                        Add New Gallery
                      </button>
                    )}
                <div className="px-[12px] py-[10px] border-[1px] border-[#D0D5DD] bg-white rounded-regular flex items-center w-full max-w-[350px]">
                  <Icon
                    icon="material-symbols:search"
                    color="#858585"
                    width="28"
                    height="28"
                  />
                  <input
                    value={searchQuery}
                    onChange={(e) => {
                      setSearchQuery(e.target.value);
                    }}
                    id="search"
                    type="text"
                    placeholder="Search"
                    aria-label="Search Input"
                    className="outline-none text-[16px] font-medium text-[#858585] ml-[12px] w-full"
                  />
                </div>
              </div>
            </div>
            <div>
              <Articles
                selectedTab={"Gallery"}
                setIsDetailsOpen={setIsDetailsOpen}
                setLibraryData={setLibraryData}
                isDetailsOpen={isDetailsOpen}
                isAddNewOpen={isAddNewOpen}
                setIsAddNewOpen={setIsAddNewOpen}
                searchQuery={searchQuery}
                setIsLightBoxOpen={setIsLightBoxOpen}
                setGalleryImages={setGalleryImages}
                profile={profile}
              />
            </div>
          </div>

        {isLightBoxOpen && (
          <Lightbox
            // toolbarButtons={[
            //   <button className="absolute top-[24px] md:top-[96px] right-[24px] md:right-[96px]">
            //     <Icon icon="material-symbols:share" width={28} color="#fff" />
            //   </button>,
            // ]}
            mainSrc={`${baseURL}/${galleryImages[photoIndex]?.path}`}
            nextSrc={`${baseURL}/${
              galleryImages[(photoIndex + 1) % galleryImages?.length]?.path
            }`}
            prevSrc={`${baseURL}/${
              galleryImages[(photoIndex - 1) % galleryImages?.length]?.path
            }`}
            onCloseRequest={() => {
              setIsLightBoxOpen(false);
            }}
            onMovePrevRequest={() =>
              setPhotoIndex(
                (prevState) =>
                  (prevState + (galleryImages?.length - 1)) %
                  galleryImages?.length
              )
            }
            onMoveNextRequest={() =>
              setPhotoIndex(
                (prevState) => (prevState + 1) % galleryImages?.length
              )
            }
            imageTitle={
              <>
                <img
                  className="cursor-pointer"
                  src={backImage}
                  alt=""
                  onClick={() => {
                    setIsLightBoxOpen(false);
                  }}
                />

                <img src={lightImage} alt="" />
                {galleryImages[photoIndex]?.originalname}
              </>
            }
          />
        )}
      </div>
    </>
  );
};

export default Gallery;
