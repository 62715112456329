import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import GoogleMapReact from "google-map-react";
import marker from "../../assets/images/mapmarker.png";
import axios from "axios";
import { statesarr } from "../../utils/StateDisc";
import { talPincode } from "../../utils/TalPincode";

const Location = ({ plotDetails, setPlotDetails }) => {
  // const [selectedLatLong, setSelectedLatLong] = useState({ lat: "", lng: "" });
  const [zoom, setZoom] = useState(6.5);
  const [center, setCenter] = useState({
    lat: 19.2515,
    lng: 74.7139,
  });

  const defaultProps = {
    center: {
      lat: 19.2515,
      lng: 74.7139,
    },
    zoom: 5,
  };

  const handleMapClick = ({ lat, lng }) => {
    setPlotDetails((prevState) => ({
      ...prevState,
      location: {
        ...prevState.location,
        latlng: [lat, lng],
      },
    }));
  };

  useEffect(() => {
    const fetchLatlongByPincode = async () => {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${plotDetails.location.pincode}&key=AIzaSyAe9PS63RZNSnCAVaiOCGYWePAtq3oGHSc`
      );
      if (response?.data[0]?.geometry?.location) {
        const { lat, lng } = response.data.results[0]?.geometry?.location;
        if (lat && lng) {
          setCenter({ lat, lng });
          // setCenter(response.data.results[0]?.geometry?.location);
          setZoom(14);
        }
      }
    };
    if (String(plotDetails.location.pincode)?.length === 6) {
      fetchLatlongByPincode();
    }
  }, [plotDetails?.location?.pincode]);

  const parseCoordinates = (coords) => {
    // Regex to match coordinates with degrees and directions (N, S, E, W)
    const regex = /(-?\d+(\.\d+)?)°?\s*(N|S|E|W)/g;
    const match = [...coords.matchAll(regex)];

    if (match.length === 2) {
      const latMatch = match[0];
      const lngMatch = match[1];

      // Parse latitude and longitude
      let lat = parseFloat(latMatch[1]);
      let lng = parseFloat(lngMatch[1]);

      // Apply directions (N/S for latitude, E/W for longitude)
      if (latMatch[3] === "S") lat = -lat; // South means negative
      if (lngMatch[3] === "W") lng = -lng; // West means negative

      // Check for NaN values and return null if invalid
      if (isNaN(lat) || isNaN(lng)) {
        console.error("Invalid coordinates:", lat, lng);
        return null;
      }

      return { lat, lng };
    } else {
      console.error("Invalid coordinate format");
      return null;
    }
  };

  return (
    <section className="mt-[32px]">
      <div className="flex items-center gap-[12px]">
        <h4 className="text-[20px] text-black font-medium">
          Property Location
        </h4>
        {/* <Icon
                    icon="material-symbols:info-outline"
                    color="#838383"
                    width={20}
                  /> */}
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-[16px] mt-[24px]">
        <div className="flex flex-col w-full">
          <label
            htmlFor="survey_number"
            className="text-[#344054] text-[14px] font-medium"
          >
            Survey Number
            <span className="text-[#C62F39]"> *</span>
          </label>
          <input
            value={plotDetails.location.survey_number}
            onChange={(e) => {
              e.target.classList.remove("!border-[#C62F39]");

              setPlotDetails((prevState) => {
                let location = { ...prevState.location };
                location["survey_number"] = e.target.value;
                return { ...prevState, location };
              });
            }}
            placeholder="Survey Number"
            id="survey_number"
            type="text"
            className="w-full mt-[8px] rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[12px] text-[16px] text-[#000E14]"
          />
        </div>
        {plotDetails.type === "single" ? (
        <div className="flex flex-col w-full">
          <label
            htmlFor="plot_number"
            className="text-[#344054] text-[14px] font-medium"
          >
            Plot Number
            <span className="text-[#C62F39]"> *</span>
          </label>
          <input
            value={plotDetails.location.plot_number}
            onChange={(e) => {
              e.target.classList.remove("!border-[#C62F39]");

              setPlotDetails((prevState) => {
                let location = { ...prevState.location };
                location["plot_number"] = e.target.value;
                return { ...prevState, location };
              });
            }}
            placeholder="Plot Number"
            id="plot_number"
            type="text"
            className="w-full mt-[8px] rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[12px] text-[16px] text-[#000E14]"
          />
        </div>
         ) : null}
         <div className="flex flex-col w-full">
          <label
            htmlFor="country"
            className="text-[#344054] text-[14px] font-medium"
          >
            Country
            <span className="text-[#C62F39]"> *</span>
          </label>
          <input
            disabled
            value={plotDetails.location.country}
            onChange={(e) => {
              e.target.classList.remove("!border-[#C62F39]");

              setPlotDetails((prevState) => {
                let location = { ...prevState.location };
                location["country"] = e.target.value;
                return { ...prevState, location };
              });
            }}
            placeholder="Country"
            id="country"
            type="text"
            className="w-full mt-[8px] rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[12px] text-[16px] text-[#000E14]"
          />
        </div>
        <div className="flex flex-col w-full">
          <label
            htmlFor="landmark"
            className="text-[#344054] text-[14px] font-medium"
          >
            Nearby Landmark
            <span className="text-[#C62F39]"> *</span>
          </label>
          <input
            value={plotDetails.location.landmark}
            onChange={(e) => {
              e.target.classList.remove("!border-[#C62F39]");

              setPlotDetails((prevState) => {
                let location = { ...prevState.location };
                location["landmark"] = e.target.value;
                return { ...prevState, location };
              });
            }}
            placeholder="Nearby Landmark"
            id="landmark"
            type="text"
            className="w-full mt-[8px] rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[12px] text-[16px] text-[#000E14]"
          />
        </div>

        <div className="flex flex-col w-full">
          <label
            htmlFor="state"
            className="text-[#344054] text-[14px] font-medium"
          >
            State
            <span className="text-[#C62F39]"> *</span>
          </label>
          {/* <input
            value={plotDetails.location.state}
            onChange={(e) => {
              e.target.classList.remove("!border-[#C62F39]");

              setPlotDetails((prevState) => {
                let location = { ...prevState.location };
                location["state"] = e.target.value;
                return { ...prevState, location };
              });
            }}
            placeholder="State"
            id="state"
            type="text"
            className="w-full mt-[8px] rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[12px] text-[16px] text-[#000E14]"
          /> */}
          <select
            value={plotDetails.location.state}
            onChange={(e) => {
              e.target.classList.remove("!border-[#C62F39]");

              setPlotDetails((prevState) => {
                let location = { ...prevState.location };
                location["state"] = e.target.value;
                location["district"] = "";
                return { ...prevState, location };
              });
            }}
            placeholder="State"
            id="state"
            type="text"
            className={`mt-[8px] rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[12px] text-[16px] text-[#000E14]`}
          >
            <option value="">Select State</option>
            {statesarr.map((state) => (
              <option value={state.state}>{state.state}</option>
            ))}
            {/* <option value="m2" selected>
                  Square Meter
                </option>
                <option value="ft2">Square Foot</option>
                <option value="g">Guntha</option>
                <option value="ac">Acre</option>
                <option value="ha">Hectare</option> */}
          </select>
        </div>
        <div className="flex flex-col w-full">
          <label
            htmlFor="district"
            className="text-[#344054] text-[14px] font-medium"
          >
            District
            <span className="text-[#C62F39]"> *</span>
          </label>
          {/* <input
            value={plotDetails.location.district}
            onChange={(e) => {
              e.target.classList.remove("!border-[#C62F39]");

              setPlotDetails((prevState) => {
                let location = { ...prevState.location };
                location["district"] = e.target.value;
                return { ...prevState, location };
              });
            }}
            placeholder="District"
            id="district"
            type="text"
            className="w-full mt-[8px] rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[12px] text-[16px] text-[#000E14]"
          /> */}

          <select
            value={plotDetails.location.district}
            onChange={(e) => {
              e.target.classList.remove("!border-[#C62F39]");

              setPlotDetails((prevState) => {
                let location = { ...prevState.location };
                location["district"] = e.target.value;
                return { ...prevState, location };
              });
            }}
            placeholder="State"
            id="state"
            type="text"
            className={`mt-[8px] rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[12px] text-[16px] text-[#000E14]`}
          >
            {plotDetails.location.state &&
              plotDetails.location.state !== "" && (
                <option value="">Select District</option>
              )}
            {plotDetails.location.state && plotDetails.location.state !== "" ? (
              statesarr
                .filter((obj) => {
                  return obj.state === plotDetails.location.state;
                })[0]
                ?.districts?.sort()
                ?.map((city, index) => (
                  <option key={index} value={city?.trim()}>
                    {city?.trim()}
                  </option>
                ))
            ) : (
              <option value="">Please select state first</option>
            )}
            {/* <option value="m2" selected>
                  Square Meter
                </option>
                <option value="ft2">Square Foot</option>
                <option value="g">Guntha</option>
                <option value="ac">Acre</option>
                <option value="ha">Hectare</option> */}
          </select>
        </div>

        <div className="flex flex-col w-full">
          <label
            htmlFor="taluka"
            className="text-[#344054] text-[14px] font-medium"
          >
            Taluka
            <span className="text-[#C62F39]"> *</span>
          </label>
          {plotDetails.location?.state === "Maharashtra" ? (
            <select
              value={plotDetails.location.taluka}
              onChange={(e) => {
                e.target.classList.remove("!border-[#C62F39]");

                setPlotDetails((prevState) => {
                  let location = { ...prevState.location };
                  location["taluka"] = e.target.value;
                  console.log(
                    talPincode.filter((obj) => {
                      return (
                        obj.district === plotDetails.location.district &&
                        obj.taluka === e.target.value
                      );
                    })[0]?.pin_code
                  );
                  location["pincode"] = talPincode.filter((obj) => {
                    return (
                      obj.district === plotDetails.location.district &&
                      obj.taluka === e.target.value
                    );
                  })[0]?.pin_code;
                  return { ...prevState, location };
                });
              }}
              placeholder="Taluka"
              id="taluka"
              type="text"
              className={`mt-[8px] rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[12px] text-[16px] text-[#000E14]`}
            >
              {plotDetails.location.district &&
                plotDetails.location.district !== "" && (
                  <option value="">Select Taluka</option>
                )}
              {plotDetails.location.district &&
              plotDetails.location.district !== "" ? (
                talPincode
                  .filter((obj) => {
                    return obj.district === plotDetails.location.district;
                  })
                  ?.sort((a, b) => a.taluka.localeCompare(b.taluka))
                  ?.map((taluka) => (
                    <option value={taluka.taluka?.trim()}>
                      {taluka.taluka?.trim()}
                    </option>
                  ))
              ) : (
                <option value="">Please select district first</option>
              )}
              {/* <option value="m2" selected>
                  Square Meter
                </option>
                <option value="ft2">Square Foot</option>
                <option value="g">Guntha</option>
                <option value="ac">Acre</option>
                <option value="ha">Hectare</option> */}
            </select>
          ) : (
            <input
              value={plotDetails.location.taluka}
              onChange={(e) => {
                e.target.classList.remove("!border-[#C62F39]");

                setPlotDetails((prevState) => {
                  let location = { ...prevState.location };
                  location["taluka"] = e.target.value;
                  return { ...prevState, location };
                });
              }}
              placeholder="Taluka"
              id="taluka"
              type="text"
              className="w-full mt-[8px] rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[12px] text-[16px] text-[#000E14]"
            />
          )}
        </div>
        <div className="flex flex-col w-full">
          <label
            htmlFor="village"
            className="text-[#344054] text-[14px] font-medium"
          >
            Village/Gaav
            <span className="text-[#C62F39]"> *</span>
          </label>
          <input
            value={plotDetails.location.village}
            onChange={(e) => {
              e.target.classList.remove("!border-[#C62F39]");

              setPlotDetails((prevState) => {
                let location = { ...prevState.location };
                location["village"] = e.target.value;
                return { ...prevState, location };
              });
            }}
            placeholder="Village/Gaav"
            id="village"
            type="text"
            className="w-full mt-[8px] rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[12px] text-[16px] text-[#000E14]"
          />
        </div>

       
        <div className="flex flex-col w-full">
          <label
            htmlFor="pincode"
            className="text-[#344054] text-[14px] font-medium"
          >
            Pincode
            <span className="text-[#C62F39]"> *</span>
          </label>
          <input
            value={plotDetails.location.pincode}
            onChange={(e) => {
              e.target.classList.remove("!border-[#C62F39]");

              setPlotDetails((prevState) => {
                let location = { ...prevState.location };
                location["pincode"] = e.target.value;
                return { ...prevState, location };
              });
            }}
            placeholder="Pincode"
            id="pincode"
            type="number"
            className="w-full mt-[8px] rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[12px] text-[16px] text-[#000E14]"
          />
        </div>
      </div>
      <div className="flex flex-col w-full mt-[24px]">
        <label
          htmlFor="map_link"
          className="text-[#344054] text-[14px] font-medium"
        >
          Map Coordinates
        </label>
        <div className="w-full mt-[8px] rounded-regular border-[1px] border-[#D0D5DD] px-[16px] py-[12px] text-[16px] text-[#000E14] flex gap-[12px]">
          <input
            onPaste={(e) => {
              e.preventDefault(); // Prevent default paste behavior
              const pastedValue = e.clipboardData.getData("text"); // Get the pasted text
              console.log("Pasted value:", pastedValue);

              // Refactor coordinates if in degree format
              const latlng = parseCoordinates(pastedValue);
              if (latlng) {
                setZoom(14);
                setCenter({
                  lat: latlng.lat,
                  lng: latlng.lng,
                });
                setPlotDetails((prevState) => {
                  let location = { ...prevState.location };
                  location["latlng"] = [latlng.lat, latlng.lng]; // Update lat/lng directly
                  return { ...prevState, location };
                });
              } else {
                console.log("Invalid coordinate format");
                setPlotDetails((prevState) => {
                  let location = { ...prevState.location };
                  location["latlng"] = []; // Clear invalid lat/lng
                  return { ...prevState, location };
                });
              }
            }}
            value={
              plotDetails?.location?.latlng
                ? plotDetails?.location?.latlng
                    .map((coord) => parseFloat(coord).toFixed(6))
                    .join(", ")
                : ""
            }
            onChange={(e) => {
              e.target.classList.remove("!border-[#C62F39]");
              const latlng = parseCoordinates(e.target.value);
              if (latlng) {
                setPlotDetails((prevState) => {
                  let location = { ...prevState.location };
                  location["latlng"] = [latlng.lat, latlng.lng]; // Update lat/lng directly
                  return { ...prevState, location };
                });
              } else {
                setPlotDetails((prevState) => {
                  let location = { ...prevState.location };
                  location["latlng"] = []; // Clear invalid lat/lng
                  return { ...prevState, location };
                });
              }
            }}
            placeholder="Map Coordinates (e.g., 20.5937° N, 78.9629° E)"
            id="map_link"
            type="text"
            className="w-full text-[16px] text-[#000E14] outline-none"
          />
          <Icon
            onClick={() => {
              navigator.geolocation.getCurrentPosition(
                function (res) {
                  setZoom(14);
                  setCenter({
                    lat: res.coords.latitude,
                    lng: res.coords.longitude,
                  });
                  setPlotDetails((prevState) => {
                    let location = { ...prevState.location };
                    location["latlng"] = [
                      res.coords.latitude,
                      res.coords.longitude,
                    ];
                    return { ...prevState, location };
                  });
                },
                function (e) {
                  console.log(e);
                }
              );
            }}
            className="text-[#0259DB] hover:text-[#0259DB] cursor-pointer"
            icon="material-symbols:my-location-outline"
            width={20}
          />
        </div>
      </div>
      <div className="mt-[24px] h-[400px] w-full">
        <GoogleMapReact
          onClick={handleMapClick}
          options={(maps) => {
            return {
              mapTypeControl: true,
              // mapTypeId: maps.MapTypeId.SATELLITE,
              mapTypeControlOptions: {
                style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: maps.ControlPosition.BOTTOM_CENTER,
                mapTypeIds: [
                  maps.MapTypeId.ROADMAP,
                  maps.MapTypeId.SATELLITE,
                  maps.MapTypeId.HYBRID,
                ],
              },

              zoomControl: true,
              clickableIcons: false,
            };
          }}
          // onClick={(ev) => {
          //   setPlotDetails((prevState) => {
          //     let location = { ...prevState.location };
          //     location["latlng"] = [ev.lat, ev.lng];
          //     return { ...prevState, location };
          //   });
          // }}
          onGoogleApiLoaded={({ map, maps }) => {
            map.addListener("click", (e) => {
              const lat = e.latLng.lat();
              const lng = e.latLng.lng();
              setPlotDetails((prevState) => {
                let location = { ...prevState.location };
                location["latlng"] = [lat, lng];
                return { ...prevState, location };
              });
              // set({ lat: ev.lat, lng: ev.lng });
            });
          }}
          // onClick={(ev) => {
          //   setPlotDetails((prevState) => {
          //     let location = { ...prevState.location };
          //     location["latlng"] = [ev.lat, ev.lng];
          //     return { ...prevState, location };
          //   });
          //   // set({ lat: ev.lat, lng: ev.lng });
          // }}
          bootstrapURLKeys={{
            key: "AIzaSyAe9PS63RZNSnCAVaiOCGYWePAtq3oGHSc",
          }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
          center={center}
          zoom={zoom}
          // yesIWantToUseGoogleMapApiInternals
          // onGoogleApiLoaded={({ map, maps }) => console.log(map, maps)}
        >
          {plotDetails.location.latlng && (
            <div
              lat={plotDetails.location.latlng[0]}
              lng={plotDetails.location.latlng[1]}
              className="mt-[-45px] ml-[-8px]"
            >
              <img src={marker} alt="mapmarker" className="w-[25px]" />
            </div>
          )}
        </GoogleMapReact>
        {/* <MapContainer
          center={[18.5204, 73.8567]}
          zoom={10}
          scrollWheelZoom={false}
          className="w-full h-full z-0"
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={[51.505, -0.09]}>
            <Popup>
              A pretty CSS3 popup. <br /> Easily customizable.
            </Popup>
          </Marker>
        </MapContainer> */}
      </div>
    </section>
  );
};

export default Location;
