import React, { useEffect, useState } from "react";
import HomeHeader from "../components/Header/HomeHeader";
import { baseURL } from "../constants";
import Footer from "../components/Footer";
import Lottie from "react-lottie";
import { useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { FaArrowRight } from "react-icons/fa6";

const PaymentStatus = () => {
  const data = new URLSearchParams(window.location.search).get("data");
  const navigate = useNavigate();

  if (!data) {
    navigate("/subscription");
  }

  const [status, setStatus] = useState(false);
  const [dataMain, setDataMain] = useState({});

  const defaultOptionsTick = {
    loop: false,
    autoplay: true,
    animationData: require("../assets/animations/tick.json"),
  };

  const defaultOptionsCancel = {
    loop: false,
    autoplay: true,
    animationData: require("../assets/animations/cancel.json"),
  };

  useEffect(() => {
    // Use jwt-decode to decode the token
    try {
      const decoded = jwtDecode(data);

      setDataMain({
        oid: decoded.orderId,
        amt: decoded.amount,
        uid: decoded.userId,
        ref: decoded.referenceNo,
      });

      if (decoded.status === "Successful" || decoded.status === "Success") {
        setStatus(true);
      }
    } catch (error) {
      console.error("Error decoding token:", error);
    }
  }, [data]);

  return (
    <>
      <HomeHeader isHome={false} />
      <div className="bg-white mx-auto max-w-screen-xl px-[24px] min-h-screen">
        <div className="mt-[24px]">
          <div className="flex items-center gap-[20px]">
            {/* <Icon icon="bx:arrow-back" color="#2B2B2B" width={24} /> */}
            <h3 className="text-[#000000] text-[24px] font-semibold">
              Payment Status
            </h3>
          </div>

          {status ? (
            <>
              <div className="flex justify-center items-center">
                <Lottie options={defaultOptionsTick} height={200} width={400} />
              </div>
              <div className="flex justify-center font-bold text-3xl -mt-4">
                Payment Successful
              </div>
              <div className="w-full mx-auto flex items-center justify-center">
                <div className="mt-4 text-lg font-extrabold text-center">
                  Thank You! Your payment of ₹{dataMain.amt}/- has been received
                </div>
              </div>

              <div className="mt-4 text-md font-semibold flex justify-center items-center">
                Your Order ID :{" "}
                <span className="font-bold text-lg ml-1">{dataMain.oid}</span>
              </div>
            </>
          ) : (
            <>
              <div className="flex flex-col items-center p-4">
                <div className="flex justify-center items-center">
                  <Lottie
                    options={defaultOptionsCancel}
                    height={150}
                    width={300}
                  />
                </div>
                <div className="text-center text-3xl font-bold mt-4">
                  Error Processing Payment
                </div>
                <div className="text-center text-md md:text-lg mt-2">
                  For payment-related issues, contact{" "}
                  <a
                  href="mailto:admin@rempo.in"
                  className="text-blue-600 mt-2 no-underline hover:underline"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  admin@rempo.in
                </a>
                </div>
              
              </div>
            </>
          )}
          <div className="flex justify-center items-center gap-4">
            {!status && (
              <button
                className="mt-6 flex items-center gap-2 bg-btnBgColor border-[2px] border-primaryColor px-[16px] py-[12px] text-primaryColor text-[16px] rounded-regular"
                onClick={() => navigate("/subscription")}
              >
                <img
                  src={"../retry.svg"}
                  width={18}
                  height={18}
                  alt=""
                  className="text-primaryColor"
                />
                Try Again
              </button>
            )}
            <button
              className="mt-6 flex items-center gap-2 bg-btnBgColor border-[2px] border-primaryColor px-[16px] py-[12px] text-primaryColor text-[16px] rounded-regular"
              onClick={() => navigate("/")}
            >
              <FaArrowRight />
              Back to Home
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PaymentStatus;
